@tailwind base;
@tailwind components;
@tailwind utilities;


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 10000 !important;
    }
